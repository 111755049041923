import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cartData",
  initialState: [],
  reducers: {
   
    addCartData(state, action) {
      const isExistingItemPresent = state.some(item => item._id.toString() === action.payload._id.toString());
      
      if (isExistingItemPresent) {
        const existingItemIndex = state.findIndex(item => item._id === action.payload._id);
        state[existingItemIndex].quantity = 1; 
      } 
      else {
        state.push({ ...action.payload, quantity: 1 });
      }
      return state; 
    },
    removeCartData(state, action) {
      const indexToRemove = state.findIndex(item => item.id === action.payload.id);

      if (indexToRemove !== -1) {
        state.splice(indexToRemove, 1);
      }
    },
    getCartData(state, action) {
      return state;
    },
  },
});

export default cartSlice.reducer;
export const { addCartData, removeCartData, getCartData } = cartSlice.actions;
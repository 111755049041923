// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAGpHLkgq5rvvtk5HLlPwRq-YATdwRyeEo",
  authDomain: "iverse-b36c2.firebaseapp.com",
  projectId: "iverse-b36c2",
  storageBucket: "iverse-b36c2.appspot.com",
  messagingSenderId: "529813189910",
  appId: "1:529813189910:web:0f707cd432fd6a8980cc37",
  measurementId: "G-ZX61YLXJH0",
};

let app, analytics, messaging;

if (typeof window !== "undefined") {
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  messaging = getMessaging(app);
}

export { app, analytics, messaging };

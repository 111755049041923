import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import userSliceReducer from "./slices/userSlice";
import feedSliceReducer from "./slices/feedSlice";
import groupSliceReducer from "./slices/groupSlice";
import mockSliceReducer from "./slices/mockSlice";
import cartSliceReducer from "./slices/cartSlice";
import eventSliceReducer from "./slices/eventSlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  userData: userSliceReducer,
  feedData: feedSliceReducer,
  groupData: groupSliceReducer,
  mockData: mockSliceReducer,
  cartData: cartSliceReducer,
  eventData:eventSliceReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

import { createSlice } from "@reduxjs/toolkit";

const eventSlice = createSlice({
  name: "eventDetail",
  initialState: {event:{},appClassInfo:{},liveAppClassInfo:{}},
  reducers: {
    addEventData(state, action) {
        console.log("action::",action.payload);
        return { ...state, event:{...action.payload} };
    },
    addAppClassData(state,action){
     //   alert('triggered!');
        return { ...state, appClassInfo:{...action.payload} };
    },
    addAppClassDetail(state,action){
        console.log("appClassDetail:",action.payload); 
        return { ...state, liveAppClassInfo:{...action.payload} };
    },
   getEventData(state, action) {
      return state;
    },
  },
});

export default eventSlice.reducer;
export const { addEventData, getEventData,addAppClassData,addAppClassDetail } = eventSlice.actions;

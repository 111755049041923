// fcm.js
import { messaging } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";

const vapidKey = "BIeQK-VVTqxbUqfa8Hs-SwUDN2zR0SpjshHfI5qMGYcQlu1hm7MoF_mAW8ZtY_cqhF9j7EqoDQ6pG-_AAc6NdMI";

export const requestFcmToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey });
    if (currentToken) {
      console.log("FCM Token:", currentToken);
      return currentToken;
    } else {
      console.warn("No registration token available. Request permission to generate one.");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token. ", error);
    return null;
  }
};

export const requestPermission = async () => {
  if (typeof window === "undefined") {
    console.error("Requesting permission only works in the browser");
    return null;
  }
  console.log("Requesting permission...");
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    console.log("Notification permission granted.");
    return await requestFcmToken();
  } else {
    console.log("Do not have permission.");
    return null;
  }
};
